import "./components/common/styles/fontFamilyRoboto.scss";
import "./components/common/styles/scrollbar.scss";
import "./components/common/styles/colors.scss";
import "./components/common/styles/colors_v2.scss";
import "./components/common/styles/app.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import App from "./components/App";

const el = document.getElementById("app") as HTMLElement;
const root = ReactDOM.createRoot(el);
root.render(<App />);